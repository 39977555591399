import { Ref, CSSProperties, useCallback } from "react";
import React from "react";

import { Control, Controller, FieldValues, Path } from "react-hook-form";
import ReactSelect, {
  SingleValue,
  MultiValue,
  Props as BaseProps,
  GroupBase,
} from "react-select";
import SelectDecl from "react-select/dist/declarations/src/Select";

import { SelectOption } from "./interface";

export interface BasicSelectProps<Option = SelectOption> {
  options: Option[];
  value: MultiValue<Option> | SingleValue<Option>;
  isMulti: boolean;
  isLoading?: boolean;
  onChange: (value: MultiValue<Option> | SingleValue<Option>) => void;
  style?: CSSProperties;
}

export type CoreProps<
  Option = SelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsMulti extends boolean = false
> = BaseProps<Option, IsMulti, Group> & {
  error?: boolean;
  innerRef?: Ref<SelectDecl<Option, IsMulti, Group>>;
};

export const Select = <
  Option = SelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsMulti extends boolean = false
>({
  options,
  value,
  isMulti,
  isLoading,
  onChange,
  ...props
}: BasicSelectProps<Option> & CoreProps<Option, Group, IsMulti>) => {
  return (
    <ReactSelect
      options={options}
      isMulti={isMulti}
      value={value}
      isLoading={isLoading}
      onChange={onChange}
      {...props}
    />
  );
};

type SelectWithControllerProps<
  FormValues extends FieldValues,
  Option = SelectOption
> = {
  control: Control<FormValues>;
  name: Path<FormValues>;
  required?: boolean;
  placeholder?: string;
  options: Option[];
  isMulti: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
};

export const SelectWithController = <
  Option = SelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsMulti extends boolean = false,
  FormValues extends FieldValues = FieldValues
>({
  control,
  name,
  required,
  placeholder,
  options,
  isMulti,
  isLoading,
  style,
}: CoreProps<Option, Group, IsMulti> &
  SelectWithControllerProps<FormValues>) => {
  const resolveValue = useCallback(
    (value: string | string[]) => {
      if (isMulti && Array.isArray(value)) {
        return options.filter((option: SelectOption) =>
          value?.includes(option.value)
        );
      }
      return options.find((option: SelectOption) => option.value === value);
    },
    [isMulti, options]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { ref, value, onChange, ...field }, fieldState }) => (
        <Select
          {...field}
          menuPlacement="auto"
          options={options}
          placeholder={placeholder}
          value={resolveValue(value)}
          innerRef={ref}
          error={!!fieldState.error}
          isMulti={isMulti}
          isLoading={isLoading}
          onChange={(value) =>
            isMulti
              ? onChange(
                  ((value ?? []) as MultiValue<SelectOption>).map(
                    (v) => v.value
                  )
                )
              : onChange(
                  value ? (value as SingleValue<SelectOption>)?.value : null
                )
          }
          style={style}
          components={{
            IndicatorSeparator: null,
          }}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              alignItems: "unset",
              padding: "var(--space-1)",
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              alignItems: "unset",
            }),
            control: (provided) => ({
              ...provided,
              minHeight: "32px",
              height: "32px",
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: "var(--font-size-2)",
              color: "var(--gray-10)",
            }),
            singleValue: (provided) => ({
              ...provided,
              fontSize: "var(--font-size-2)",
              color: "var(--black)",
            }),
            input: (provided) => ({
              ...provided,
              transform: "translateY(-5px)",
            }),
            option: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
          }}
        />
      )}
    />
  );
};
