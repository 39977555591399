import { Flex, RadioGroup as RadixRadioGroup, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  options: {
    label: string;
    value: string;
    id?: string;
  }[];
  direction?: "row" | "column";
};

const RadioGroupItem = styled(RadixRadioGroup.Item, {
  "> button": {
    cursor: "pointer",
  },
});

export const RadioGroup = ({ value, onChange, options, direction }: Props) => {
  return (
    <RadixRadioGroup.Root value={value} onValueChange={onChange} size="1">
      <Flex
        direction={{ initial: "column", xs: direction ?? "column" }}
        gap="2"
        wrap={"wrap"}
      >
        {options.map((option) => {
          return (
            <Text as="label" key={option.value} size="2">
              <Flex
                gap="2"
                style={{
                  alignItems: "center",
                }}
                id={option.id}
              >
                <RadioGroupItem key={option.value} value={option.value} />
                {option.label}
              </Flex>
            </Text>
          );
        })}
      </Flex>
    </RadixRadioGroup.Root>
  );
};
