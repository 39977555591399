import { useQuery, UseQueryOptions } from "react-query";

import { ApiError, Feature, UsersService } from "../api/public";

const REFETCH_INTERVAL = 1000 * 60; // 1 minute

export const useGetFeatures = (options?: UseQueryOptions) => {
  return useQuery<Feature[] | undefined, ApiError, Feature[]>(
    "user-features",
    async () => {
      const { data, error } = await UsersService.usersGetUserFeatures();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      onError: options?.onError,
      enabled: options?.enabled,
    }
  );
};
