import { PropsWithChildren, ReactNode } from "react";

import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Flex, Callout as RadixCallout } from "@radix-ui/themes";

type Props = PropsWithChildren<
  {
    actions?: ReactNode;
    icon?: ReactNode;
  } & React.ComponentProps<typeof RadixCallout.Root>
>;

export const Callout = ({ children, icon, actions, ...props }: Props) => {
  return (
    <RadixCallout.Root
      {...props}
      highContrast
      style={{
        display: "flex",
      }}
    >
      <RadixCallout.Icon>{icon ?? <InfoCircledIcon />}</RadixCallout.Icon>
      <Flex
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
        gap="4"
      >
        <RadixCallout.Text highContrast>{children}</RadixCallout.Text>
        {actions}
      </Flex>
    </RadixCallout.Root>
  );
};
