export enum Routes {
  signup = "/signup",
  setupProfile = "/setup",
  setupProfileWithInviteCode = "/setup/:inviteCode",
  home = "/",

  blends = "/blends",
  blend = "/blends/:resultId",

  results = "/results",
  result = "/results/:resultId",
  copyResult = "/results/:resultId/copy",

  profile = "/profile",
  helpCenter = "/help-center",
  appHelpCenter = "/app/help-center",

  internal = "/internal",
  briefs = "/product-briefs",
  brief = "/product-briefs/:productBriefId",
  brief_deformulation = "/product-briefs/:productBriefId/deformulation/:deformulationId",
}

export const getResultsRoute = (resultId: string, defaultOpen?: number) => {
  if (defaultOpen === undefined) {
    return `${Routes.results}/${resultId}`;
  }
  return `${Routes.results}/${resultId}?defaultOpen=${defaultOpen}`;
};

export const getSetupProfileRoute = (inviteCode: string) => {
  return `${Routes.setupProfile}${inviteCode}`;
};

export const getBlendResultsRoute = (resultId: string) => {
  return `${Routes.blends}/${resultId}`;
};

export const getProductBriefRoute = (productBriefId: string) => {
  return `${Routes.briefs}/${productBriefId}`;
};

export const getProductBriefDeformulationRoute = (
  productBriefId: string,
  deformulationId?: string
) => {
  if (!deformulationId) {
    return `${Routes.briefs}/${productBriefId}`;
  }
  return `${Routes.briefs}/${productBriefId}/deformulation/${deformulationId}`;
};
