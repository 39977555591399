import { Theme } from "@radix-ui/themes";

import { OpenAPI } from "./api/public";
import { AppRouter } from "./AppRouter";
import { useTheme } from "./common/hooks/useTheme";

import {} from "@auth0/auth0-react";
import { ToastContextProvider } from "./state/ToastContext";

OpenAPI.BASE =
  process.env.REACT_APP_PUBLIC_API_URL || "http://localhost:8001/public/v1";

function App() {
  const theme = useTheme();

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Theme appearance={theme as any} accentColor="indigo">
      <ToastContextProvider>
        <AppRouter />
      </ToastContextProvider>
    </Theme>
  );
}

export default App;
