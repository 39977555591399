import { useContext } from "react";

import { AppContext } from "../../../state/AppContext";

type Props = {
  feature: string;
};

export const RestrictedFeature = ({
  children,
  feature,
}: React.PropsWithChildren<Props>) => {
  const { features } = useContext(AppContext);

  const hasFeature = features.has(feature);

  if (!hasFeature) {
    return null;
  }

  return <>{children}</>;
};

export default RestrictedFeature;
