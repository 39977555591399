import { PropsWithChildren } from "react";

import {
  InfoCircledIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  Flex,
  Grid,
  Inset,
  Popover,
  PopoverContent,
} from "@radix-ui/themes";

import { Button } from "../Button";
import { Tag } from "../Tag";
type Props = PropsWithChildren<{
  defaultOpen?: boolean;
  markAsSeen?: () => void;
  width?: string;
}>;
export const Beta = ({
  defaultOpen,
  markAsSeen,
  children,
  width = "300px",
}: Props) => (
  <Popover.Root defaultOpen={defaultOpen} onOpenChange={markAsSeen}>
    <Popover.Trigger>
      <Button variant="ghost" style={{ padding: 0 }}>
        <Tag
          highContrast={false}
          style={{ cursor: "pointer", background: "none" }}
        >
          Beta
          <QuestionMarkCircledIcon width="16" height="16" />
        </Tag>
      </Button>
    </Popover.Trigger>
    <PopoverContent size="2">
      <Grid columns="24px 1fr" gap="4">
        <Inset
          side="left"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--accent-9)",
            color: "var(--accent-9-contrast)",
          }}
        >
          <InfoCircledIcon width="24px" height="24px" />
        </Inset>
        <Flex direction="column" gap="2">
          <Box
            style={{
              width,
            }}
          >
            {children}
          </Box>
          <Popover.Close>
            <Button variant="outline">Close</Button>
          </Popover.Close>
        </Flex>
      </Grid>
    </PopoverContent>
  </Popover.Root>
);
