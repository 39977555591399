import { useEffect, useState } from "react";

export const useTheme = () => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    if (mq.matches) {
      setIsDark(true);
    }

    mq.addEventListener("change", (e) => {
      setIsDark(e.matches);
    });
  }, []);

  useEffect(() => {
    if (isDark) {
      //document.getElementsByTagName("body").item(0)?.classList.add("dark");
    } else {
      document.getElementsByTagName("body").item(0)?.classList.remove("dark");
    }
  }, [isDark]);
  return "light";
};
