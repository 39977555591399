import React from "react";

import { init as initFullStory } from "@fullstory/browser";
import ReactDOM from "react-dom/client";
import "@radix-ui/themes/styles.css";
import "./common/components/Table/styles.css";
import "./styles.css";
import { QueryClientProvider, QueryClient } from "react-query";

import App from "./App";

const queryClient = new QueryClient();
const art = `
                  ;+;;+++;;
                 ;+++++++++;;
        ;;++;+  ;++++++++++;+  +;++++
      ;+++++++  ;++++++++;+  +;++++++++;
     ;++++++++  +++++++++;  ;++;+++++++;+
    ;+++++;;  +;++;;  +  +;;+;;  ;++++++;
    +++++;  +++++++++  ;+++++++++  ++++++
     ++++  ++++++++;  ;++++++++++;; ;++++
     ;;+; ;+++++++;  ++++++++++++++  ;;;
 ;+++  ;  ;+++++++;  ++++++++++++++  ;  ++;+
;+++++++  ;+++;;;;;  ++++++++++++++  ;;++++++
++++++++++ +  ;;;+++  ;;++++++++;;  ;++++++++
;+++++++++  ;+++++++;   +++;+;;;   ;;++++++++;
++++++++;  ;++++++++  ;;+       ++; ++;++++++
 ++++++++ ;++++++++; ;++++;;++;;++;     ;++++
   ;++++  ;++++++++  ++++++++++++++  ;+; +;
          ++++++++++ +++++++++++++;  +++;
    ;;;;++ ;++++++++; +;++++++++;+  ;++++
    ++++++;  +;;++++;   +;;;+;++  +++++++
    +++++++;+         ;;        +;+++++;+
     ++++++++;;+;  ;++++;;;;;;  +++++++;
       ;++;+;;++ ;+++++++++++;  ;+++++
                 ++++++++++++;
                  ++++++++++;
                    ;;;;;;
© 2024 Potion, Inc.
`;

// eslint-disable-next-line no-console
console.log(art);

if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
  initFullStory({
    orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
    recordCrossDomainIFrames: true,
    debug: !!process.env.REACT_APP_FULLSTORY_DEBUG_MODE,
    devMode: !!process.env.NODE_ENV && process.env.NODE_ENV === "development",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
