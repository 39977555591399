/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanResponse } from "../models/BooleanResponse";
import type { DeleteProductsRequest } from "../models/DeleteProductsRequest";
import type { GetProductSimilarityRequest } from "../models/GetProductSimilarityRequest";
import type { GetSimilarProductsRequest } from "../models/GetSimilarProductsRequest";
import type { PaginatedResponse_list_definitions_public_v1_product_ProductOption__ } from "../models/PaginatedResponse_list_definitions_public_v1_product_ProductOption__";
import type { ReingestProductsRequest } from "../models/ReingestProductsRequest";
import type { Response_list_definitions_public_v1_product_Product__ } from "../models/Response_list_definitions_public_v1_product_Product__";
import type { Response_str_ } from "../models/Response_str_";
import type { UpdateProductRequest } from "../models/UpdateProductRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class ProductsService {
  /**
   * Get Similar Products
   * @param requestBody
   * @returns Response_list_definitions_public_v1_product_Product__ Successful Response
   * @throws ApiError
   */
  public static productsGetSimilarProducts(
    requestBody: GetSimilarProductsRequest
  ): CancelablePromise<Response_list_definitions_public_v1_product_Product__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/products/similar-products",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get New Products
   * @param limit
   * @returns Response_list_definitions_public_v1_product_Product__ Successful Response
   * @throws ApiError
   */
  public static productsGetNewProducts(
    limit: number
  ): CancelablePromise<Response_list_definitions_public_v1_product_Product__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/products/new",
      query: {
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Product Similarity
   * @param requestBody
   * @returns Response_str_ Successful Response
   * @throws ApiError
   */
  public static productsGetProductSimilarity(
    requestBody: GetProductSimilarityRequest
  ): CancelablePromise<Response_str_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/products/similarity",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Reingest Products
   * @param requestBody
   * @param fetchReviews
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productsReingestProducts(
    requestBody: ReingestProductsRequest,
    fetchReviews: boolean = false
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/products/reingest",
      query: {
        fetch_reviews: fetchReviews,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Products
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productsDeleteProducts(
    requestBody: DeleteProductsRequest
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/products/delete",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Product
   * @param productId
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productsUpdateProduct(
    productId: string,
    requestBody: UpdateProductRequest
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/products/{product_id}",
      path: {
        product_id: productId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Flagged Products
   * @returns Response_list_definitions_public_v1_product_Product__ Successful Response
   * @throws ApiError
   */
  public static productsGetFlaggedProducts(): CancelablePromise<Response_list_definitions_public_v1_product_Product__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/products/flagged",
    });
  }
  /**
   * Search Products
   * @param qs
   * @param limit
   * @param offset
   * @returns PaginatedResponse_list_definitions_public_v1_product_ProductOption__ Successful Response
   * @throws ApiError
   */
  public static productsSearchProducts(
    qs: string,
    limit: number,
    offset?: number
  ): CancelablePromise<PaginatedResponse_list_definitions_public_v1_product_ProductOption__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/products/search",
      query: {
        qs: qs,
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
