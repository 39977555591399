/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanResponse } from "../models/BooleanResponse";
import type { CreateDynamicSearchExamplesRequest } from "../models/CreateDynamicSearchExamplesRequest";
import type { EmbedDynamicSearchExamplesRequest } from "../models/EmbedDynamicSearchExamplesRequest";
import type { EnhancedSearchRequest } from "../models/EnhancedSearchRequest";
import type { LogSearchActionRequest } from "../models/LogSearchActionRequest";
import type { Response_EnhancedSearchResponse_Product__ } from "../models/Response_EnhancedSearchResponse_Product__";
import type { Response_EnhancedSearchResponse_RawMaterial__ } from "../models/Response_EnhancedSearchResponse_RawMaterial__";
import type { Response_list_definitions_public_v1_search_DynamicExample__ } from "../models/Response_list_definitions_public_v1_search_DynamicExample__";
import type { Response_list_definitions_public_v1_search_PreviousSearch__ } from "../models/Response_list_definitions_public_v1_search_PreviousSearch__";
import type { SearchNamespace } from "../models/SearchNamespace";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class SearchService {
  /**
   * Hybrid Search Raw Materials
   * @param requestBody
   * @returns Response_EnhancedSearchResponse_RawMaterial__ Successful Response
   * @throws ApiError
   */
  public static searchHybridSearchRawMaterials(
    requestBody: EnhancedSearchRequest,
  ): CancelablePromise<Response_EnhancedSearchResponse_RawMaterial__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/raw-materials",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Stream
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static searchStream(
    requestBody: EnhancedSearchRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/raw-materials/stream",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Search History
   * @param namespace
   * @param limit
   * @returns Response_list_definitions_public_v1_search_PreviousSearch__ Successful Response
   * @throws ApiError
   */
  public static searchGetSearchHistory(
    namespace: SearchNamespace,
    limit: number = 25,
  ): CancelablePromise<Response_list_definitions_public_v1_search_PreviousSearch__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/search/history",
      query: {
        namespace: namespace,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Log Search Action
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static searchLogSearchAction(
    requestBody: LogSearchActionRequest,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/actions",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Search Products
   * @param requestBody
   * @returns Response_EnhancedSearchResponse_Product__ Successful Response
   * @throws ApiError
   */
  public static searchSearchProducts(
    requestBody: EnhancedSearchRequest,
  ): CancelablePromise<Response_EnhancedSearchResponse_Product__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/products",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Stream Products
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static searchStreamProducts(
    requestBody: EnhancedSearchRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/products/stream",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * List Dynamic Examples
   * @param namespace
   * @returns Response_list_definitions_public_v1_search_DynamicExample__ Successful Response
   * @throws ApiError
   */
  public static searchListDynamicExamples(
    namespace: SearchNamespace,
  ): CancelablePromise<Response_list_definitions_public_v1_search_DynamicExample__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/search/examples",
      query: {
        namespace: namespace,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Dynamic Examples
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static searchCreateDynamicExamples(
    requestBody: CreateDynamicSearchExamplesRequest,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/examples",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Dynamic Examples
   * @param dynamicExampleId
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static searchDeleteDynamicExamples(
    dynamicExampleId: string,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/search/examples/{dynamic_example_id}",
      path: {
        dynamic_example_id: dynamicExampleId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Embed Dynamic Examples
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static searchEmbedDynamicExamples(
    requestBody: EmbedDynamicSearchExamplesRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/examples/embed",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
