import { Ref, useCallback } from "react";

import { styled } from "@stitches/react";
import { omit } from "lodash";
import { GroupBase, InputActionMeta, MenuListProps } from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import {
  AsyncPaginate,
  AsyncPaginateProps as BaseProps,
  wrapMenuList,
} from "react-select-async-paginate";
import type { ShouldLoadMore } from "react-select-async-paginate";

import { SelectOption } from "./interface";
import { ScrollArea } from "../ScrollArea";
import { Spinner } from "../Spinner";

export type AsyncSelectProps<
  Option = SelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional = Record<string, unknown>,
  IsMulti extends boolean = false
> = BaseProps<Option, Group, Additional, IsMulti> & {
  error?: boolean;
  innerRef?: Ref<Select<Option, IsMulti, Group>>;
};

const shouldLoadMore: ShouldLoadMore = (
  scrollHeight,
  clientHeight,
  scrollTop
) => {
  const bottomBorder = (scrollHeight - clientHeight) / 1.5;

  return bottomBorder < scrollTop;
};

export const AsyncSelect = <
  Option = SelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional = Record<string, unknown>,
  IsMulti extends boolean = false
>({
  innerRef,
  onInputChange,
  hideSelectedOptions,
  ...props
}: AsyncSelectProps<Option, Group, Additional, IsMulti> & {
  error?: boolean;
  innerRef?: Ref<Select<Option, IsMulti, Group>>;
}) => {
  const handleInputChange = useCallback(
    (value: string, action: InputActionMeta) => {
      onInputChange?.(value, action);
    },
    [onInputChange]
  );

  return (
    <AsyncPaginate
      isMulti={props.isMulti}
      selectRef={innerRef}
      debounceTimeout={250}
      onInputChange={handleInputChange}
      menuPlacement="auto"
      hideSelectedOptions={hideSelectedOptions}
      components={{
        LoadingMessage: CustomLoadingMessage,
        MenuList: wrapMenuList(CustomMenuList),
        ...props.components,
      }}
      shouldLoadMore={shouldLoadMore}
      styles={{
        container: (provided) => ({ ...provided, width: "100%" }),
        option: (base, props) => {
          return {
            ...base,
            cursor: "pointer",
            backgroundColor: props.isSelected
              ? "var(--accent-4)"
              : props.isFocused
              ? "var(--accent-3)"
              : undefined,
            color: "var(--gray-12)",
            "&:hover": {
              backgroundColor: props.isSelected ? undefined : "var(--accent-3)",
            },
          };
        },
        noOptionsMessage: (base) => ({
          ...base,
          color: "var(--gray-9)",
          fontSize: "var(--font-size-2)",
          height: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }),
        placeholder: (base) => ({
          ...base,
          color: "var(--gray-9)",
          fontSize: "var(--font-size-2)",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }),
        control: (base) => ({
          ...base,
          cursor: "pointer",
          maxHeight: "var(--space-5)",
        }),
        ...props.styles,
      }}
      {...omit(props, ["styles", "components"])}
    />
  );
};

const LoadingContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "200px",
  justifyContent: "center",
});

const CustomLoadingMessage = () => (
  <LoadingContainer>
    <Spinner size="lg" centered />
  </LoadingContainer>
);

const CustomMenuList = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  innerRef,
}: MenuListProps<Option, IsMulti, Group>) => {
  return (
    <ScrollArea
      ref={innerRef}
      style={{
        maxHeight: "200px",
        borderRadius: 4,
        height: "auto",
        paddingTop: "var(--space-1)",
        paddingBottom: "var(--space-1)",
      }}
    >
      {children}
    </ScrollArea>
  );
};
