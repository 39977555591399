import { Switch as RadixSwitch } from "@radix-ui/themes";
import { styled } from "@stitches/react";

import "./styles.css";

export const Switch = styled(RadixSwitch, {
  cursor: "pointer",
  "&:disabled": {
    cursor: "not-allowed",
  },
});
