import * as Progress from "@radix-ui/react-progress";
import { keyframes, styled } from "@stitches/react";

const ProgressRoot = styled(Progress.Root, {
  position: "relative",
  overflow: "hidden",
  background: "var(--gray-4)",
  borderRadius: "var(--radius-2)",
  width: "100%",
  height: "var(--space-1)",
  transform: "translateZ(0)",
});

const ProgressIndicator = styled(Progress.Indicator, {
  backgroundColor: "var(--accent-9)",
  width: "100%",
  height: "100%",
  transition: "transform 1200ms cubic-bezier(0.65, 0, 0.35, 1)",
});

const makeSlideAnimation = (max: number) => {
  return keyframes({
    from: { transform: "translateX(-100%)" },
    to: { transform: `translateX(-${100 - max}%)` },
  });
};

type ProgressBarProps = {
  duration?: `${number}s`;
  max?: number;
  progressFn?:
    | `cubic-bezier(${number},${number},${number},${number})`
    | "linear"
    | "ease-in"
    | "ease-out"
    | "ease-in-out";
  height?: number;
  radius?: number;
};

const ProgressBar = ({
  progressFn = "linear",
  duration = "30s",
  max = 100,
  height = 1,
  radius = 1,
}: ProgressBarProps) => {
  return (
    <ProgressRoot
      style={{
        height: `var(--space-${height})`,
        borderRadius: `var(--radius-${radius})`,
      }}
    >
      <ProgressIndicator
        style={{
          animation: `${makeSlideAnimation(max)} ${duration} ${progressFn}`,
        }}
      />
    </ProgressRoot>
  );
};

export default ProgressBar;
