/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterial__ } from "../models/PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterial__";
import type { PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterialOption__ } from "../models/PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterialOption__";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class RawMaterialsService {
  /**
   * Search Raw Materials
   * @param qs A search term to filter raw materials by
   * @param limit The maximum number of raw materials to return
   * @param offset The number of raw materials to skip
   * @returns PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterialOption__ Successful Response
   * @throws ApiError
   */
  public static rawMaterialsSearchRawMaterials(
    qs: string,
    limit: number = 10,
    offset?: number,
  ): CancelablePromise<PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterialOption__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/raw-materials/search",
      query: {
        qs: qs,
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * List Popular Raw Materials
   * @param limit The maximum number of raw materials to return
   * @param offset The number of raw materials to skip
   * @returns PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterial__ Successful Response
   * @throws ApiError
   */
  public static rawMaterialsListPopularRawMaterials(
    limit: number = 10,
    offset?: number,
  ): CancelablePromise<PaginatedResponse_list_definitions_public_v1_raw_materials_RawMaterial__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/raw-materials/popular",
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
