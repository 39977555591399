import React from "react";

import { TextArea, MarginProps } from "@radix-ui/themes";

type TextareaProps = Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  "color" | "size"
> &
  MarginProps;
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props: TextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    return <TextArea {...props} ref={ref} size={"2"} variant="surface" />;
  }
);

export default Textarea;
