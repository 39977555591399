/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum ReferralSource {
  CLOSED_BETA = "closed_beta",
  LINKEDIN = "linkedin",
  GOOGLE = "google",
  COLLEAGUE = "colleague",
  NETWORK = "network",
  CONFERENCE = "conference",
  SUPPLIER_OR_DISTRIBUTOR = "supplier_or_distributor",
  OTHER = "other",
}
