import React, { PropsWithChildren, useCallback } from "react";

import { Cross1Icon } from "@radix-ui/react-icons";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { styled, keyframes } from "@stitches/react";

import { useToastContext } from "../../../state/ToastContext";

type Props = {
  icon?: React.ReactNode;
  altText?: string;
  variant?: "success" | "error" | "warning" | "info";
  animationEffect?: "none" | "slideIn";
  dismissable?: boolean;
} & React.ComponentProps<typeof ToastPrimitive.Root>;

export type ToastProps = PropsWithChildren<Props>;

const TOAST_WIDTH_PX = 350;

const slideIn = keyframes({
  from: { transform: "translateY(100%)", opacity: 0 },
  to: { transform: "translateY(0)", opacity: 1 },
});

const ToastRoot = styled(ToastPrimitive.Root, {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  gap: "var(--space-1)",
  padding: "var(--space-2)",
  borderRadius: "var(--radius-3)",
  boxShadow: "0 0px 16px rgba(0, 0, 0, 0.2)",

  width: `${TOAST_WIDTH_PX}px`,
  left: `calc(50vw - ${TOAST_WIDTH_PX / 2}px)`,

  zIndex: 9999,

  background: "var(--gray-1)",
  color: "var(--gray-12)",

  variants: {
    variant: {
      success: {
        color: "var(--green-11)",
        background: "var(--green-4)",
      },
      error: {
        color: "var(--red-11)",
        background: "var(--red-4)",
      },
      warning: {
        color: "var(--amber-11)",
        background: "var(--amber-6)",
      },
      info: {
        color: "var(--blue-11)",
        background: "var(--blue-1)",
      },
    },
    animationEffect: {
      none: {
        animation: "none",
      },
      slideIn: {
        animation: `${slideIn} 0.3s cubic-bezier(0.16, 1, 0.3, 1)`,
      },
    },
  },
});

const ToastTitle = styled(ToastPrimitive.Title, {
  fontWeight: "600",
  marginBottom: "var(--space-1)",
  display: "flex",
  alignItems: "center",
  gap: "var(--space-2)",
});

const ToastContent = styled(ToastPrimitive.Description, {
  marginBottom: "var(--space-1)",
  fontSize: "var(--font-size-1)",
  padding: "var(--space-1)",
});

const ToastAction = styled(ToastPrimitive.Action, {
  display: "flex",
  gap: "var(--space-1)",
});

const CloseButton = styled(ToastPrimitive.Close, {
  position: "absolute",
  top: "var(--space-2)",
  right: "var(--space-2)",
  cursor: "pointer",
  padding: "var(--space-1)",
  borderRadius: "var(--radius-2)",
});

export const Toast = ({
  icon,
  title,
  content,
  children,
  altText,
  dismissable = true,
  ...props
}: ToastProps) => {
  const { closeToast } = useToastContext();

  const handleClose = useCallback(() => {
    closeToast();
  }, [closeToast]);

  return (
    <ToastRoot
      {...props}
      onOpenChange={(open) => {
        props.onOpenChange?.(open);
        if (!open) {
          handleClose();
        }
      }}
    >
      {title && (
        <ToastTitle>
          {icon}
          {title}
        </ToastTitle>
      )}
      {!!content && <ToastContent>{content}</ToastContent>}
      {children && (
        <ToastAction asChild altText={altText ?? "toast-action"}>
          {children}
        </ToastAction>
      )}
      {dismissable && (
        <CloseButton aria-label="Close" asChild>
          <Cross1Icon />
        </CloseButton>
      )}
    </ToastRoot>
  );
};
