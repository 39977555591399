import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { FullStory } from "@fullstory/browser";
import { Flex, Strong, Text } from "@radix-ui/themes";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import { ApiError, OpenAPI } from "./api/public";
import { AuthProvider } from "./AuthProvider";
import { Modal } from "./common/components";
import { Button } from "./common/components/Button";
import { ErrorBoundary } from "./common/components/ErrorBoundary";
import { PotionLogoSpinner } from "./common/components/Spinner";
import { useIntercom } from "./common/hooks/useIntercom";
import { useCheckProfileCompleteness } from "./hooks/useCheckProfileCompleteness";
import { useGetFeatures } from "./hooks/useGetFeatures";
import { Routes as ROUTES } from "./routes";
import { AppContext } from "./state/AppContext";

const AUTO_LOGOUT_DELAY_MS = 20 * 1000;

const Auth0ProtectedRoutes = withAuthenticationRequired(
  () => {
    const { getAccessTokenSilently } = useAuth0();

    const getToken = useCallback(async () => {
      return getAccessTokenSilently();
    }, [getAccessTokenSilently]);
    OpenAPI.TOKEN = getToken;

    return (
      <>
        <Outlet />
      </>
    );
  },
  { onRedirecting: () => <PotionLogoSpinner size="md" /> }
);

const PotionProtectedRoutes = () => {
  const { logout } = useAuth0();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const onGetMeError = useCallback((error: unknown) => {
    if (!(error instanceof ApiError)) return;
    switch (error.status) {
      case 409:
        setErrorMessage(`${error.body.message}`);
        break;
      default:
        return;
    }
  }, []);
  const { me, isLoading, error } = useCheckProfileCompleteness({
    onError: onGetMeError,
  });
  const { data: features } = useGetFeatures();

  useEffect(() => {
    if (error?.status === 409) {
      const timer = setTimeout(() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }, AUTO_LOGOUT_DELAY_MS);
      return () => clearTimeout(timer);
    }
  }, [error, logout]);

  useEffect(() => {
    if (me && !!process.env.NODE_ENV) {
      let displayName = [me.first_name, me.last_name].filter(Boolean).join(" ");
      if (!displayName) {
        displayName = me.email;
      }
      FullStory("setIdentity", {
        uid: me.id,
        properties: {
          email: me.email,
          displayName,
          company: me.company_name,
          environment: process.env.NODE_ENV,
        },
      });
    }
  }, [me]);

  useIntercom(me);

  const appContextValue = useMemo(() => {
    return {
      features: new Set(features?.map((f) => f.name) ?? []),
    };
  }, [features]);

  if (!me && errorMessage !== "") {
    return (
      <Modal
        title="Login Error"
        open={errorMessage !== ""}
        onClose={() => void undefined}
        size="2"
      >
        <>
          <Flex direction="column" gap="2" mb="3">
            <Text size="2" weight="bold">
              {errorMessage}
            </Text>
            <Text size="2">
              Please log out and try again with the suggested login method.
              Otherwise, you will be automatically logged out in{" "}
              <Strong>{AUTO_LOGOUT_DELAY_MS / 1000} seconds.</Strong>
            </Text>
          </Flex>
          <Button
            variant="solid"
            color="red"
            style={{ width: "100%" }}
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <Text>Log out</Text>
          </Button>
        </>
      </Modal>
    );
  }

  if (isLoading || error || !me) {
    return <PotionLogoSpinner size="xs" />;
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <Outlet />
    </AppContext.Provider>
  );
};

const ProductBriefDeformulationResults = React.lazy(
  () => import("./pages/VirtualFormulation/ProductBriefDeformulationResult")
);

const Benchmarking = React.lazy(() => import("./pages/Benchmarking"));

const ProductBriefDetail = React.lazy(
  () => import("./pages/VirtualFormulation/ProductBriefDetail")
);

const DeformulateResults = React.lazy(
  () => import("./pages/DeformulateResults")
);
const CopyDeformulateResult = React.lazy(
  () => import("./pages/CopyDeformulateResult")
);

const SetupProfile = React.lazy(() => import("./pages/SetupProfile"));
const ProfilePage = React.lazy(() => import("./pages/Profile"));

const TutorialsPage = React.lazy(() => import("./pages/Tutorials"));
const InternalTestingPage = React.lazy(() => import("./pages/InternalTesting"));

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <Auth0ProtectedRoutes />,
        children: [
          {
            path: ROUTES.setupProfile,
            element: <SetupProfile />,
          },
          {
            path: ROUTES.setupProfileWithInviteCode,
            element: <SetupProfile />,
          },
          {
            path: ROUTES.signup,
            element: <SetupProfile />,
          },
          {
            element: <PotionProtectedRoutes />,
            children: [
              {
                path: ROUTES.home,
                element: <Benchmarking />,
              },
              {
                path: ROUTES.profile,
                element: <ProfilePage />,
              },
              {
                path: ROUTES.result,
                element: <DeformulateResults />,
              },
              {
                path: ROUTES.brief_deformulation,
                element: <ProductBriefDeformulationResults />,
              },
              {
                path: ROUTES.brief,
                element: <ProductBriefDetail />,
              },
              {
                path: ROUTES.copyResult,
                element: <CopyDeformulateResult />,
              },
              {
                path: ROUTES.helpCenter,
                element: <TutorialsPage />,
              },
              {
                path: ROUTES.appHelpCenter,
                element: <TutorialsPage />,
              },
              {
                path: ROUTES.internal,
                element: <InternalTestingPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const AppRouter = () => {
  useEffect(() => {
    document.title = "Potion AI";
  }, []);

  return (
    <Suspense fallback={<PotionLogoSpinner size="xs" />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
