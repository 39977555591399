export const PotionIcon = ({ size = "1em", ...props }) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      color={props.color ?? "#006ACD"}
    >
      <g clipPath="url(#clip0)">
        <path d="M36 17.9172C35.9992 16.9336 35.7291 15.969 35.2191 15.1279C34.7091 14.2869 33.9785 13.6015 33.1067 13.146C32.1895 14.5013 30.8506 15.516 29.2977 16.0326C29.0443 16.7083 28.6933 17.3433 28.2559 17.9172C28.606 18.3784 28.9001 18.8797 29.1318 19.4103C30.8408 19.9334 32.3024 21.0579 33.2461 22.5757C34.0743 22.11 34.765 21.4341 35.2485 20.6162C35.732 19.7983 35.9912 18.8673 36 17.9172Z" />
        <path d="M29.7223 21.8521C29.7223 22.0246 29.7223 22.1971 29.7223 22.3697C29.7201 23.7748 29.3168 25.1501 28.5597 26.3339C27.8027 27.5177 26.7234 28.4607 25.4487 29.0521C25.5445 29.5239 25.5934 30.004 25.5947 30.4854C25.5899 30.8813 25.5544 31.2762 25.4885 31.6666C26.7481 32.0275 28.0961 31.9165 29.2797 31.3544C30.4633 30.7923 31.4012 29.8177 31.9174 28.6134C32.4337 27.4091 32.4928 26.0579 32.0838 24.8131C31.6748 23.5683 30.8257 22.5154 29.6957 21.8521H29.7223Z" />
        <path d="M23.6307 30.4854C23.6267 30.2031 23.6023 29.9214 23.5577 29.6426C23.165 29.7082 22.7679 29.7437 22.3699 29.7488C20.8221 29.7505 19.314 29.2601 18.0631 28.3486C17.4145 28.8215 16.6926 29.1847 15.9264 29.4237C15.3578 30.708 14.4376 31.8054 13.272 32.589C13.756 33.7349 14.6217 34.6781 15.722 35.2584C16.8224 35.8387 18.0896 36.0204 19.3087 35.7727C20.5278 35.525 21.6236 34.8631 22.4101 33.8993C23.1967 32.9356 23.6256 31.7294 23.624 30.4854H23.6307Z" />
        <path d="M13.4246 29.7292C12.011 29.6676 10.6451 29.1998 9.49053 28.3819C8.33595 27.5639 7.44166 26.4304 6.91478 25.1172C6.41256 25.2208 5.90129 25.2741 5.38851 25.2765C4.89828 25.2755 4.40931 25.2266 3.9286 25.1305C3.6103 26.3359 3.72098 27.6146 4.24167 28.7475C4.76236 29.8803 5.66069 30.7969 6.78283 31.3404C7.90497 31.8838 9.18112 32.0202 10.3928 31.7263C11.6044 31.4324 12.6762 30.7264 13.4246 29.7292Z" />
        <path d="M9.65638e-06 17.9172C0.00176571 19.3457 0.570033 20.7153 1.58017 21.7254C2.59031 22.7356 3.95985 23.3038 5.3884 23.3056C5.7357 23.3026 6.08208 23.2693 6.4236 23.206C6.39159 22.9352 6.37608 22.6626 6.37715 22.3898C6.36635 20.7748 6.89146 19.2018 7.87024 17.9172C7.42732 17.3312 7.07397 16.6827 6.82176 15.9928C5.25483 15.4753 3.90515 14.4502 2.98618 13.0796C2.08747 13.528 1.33178 14.2185 0.804203 15.0731C0.276626 15.9278 -0.00189033 16.9128 9.65638e-06 17.9172Z" />
        <path d="M6.38398 13.6302C6.38398 13.5903 6.38398 13.5439 6.38398 13.5041C6.38545 12.0361 6.82434 10.6019 7.64462 9.38447C8.46489 8.16705 9.62934 7.22169 10.9893 6.66906C10.9125 6.24642 10.8725 5.81789 10.8699 5.38832C10.868 4.91617 10.9125 4.44498 11.0026 3.9815C10.4043 3.75698 9.77032 3.64233 9.13126 3.64307C7.95905 3.65115 6.82154 4.04175 5.89163 4.75548C4.96173 5.4692 4.29028 6.46703 3.97937 7.59729C3.66846 8.72754 3.73508 9.92839 4.1691 11.0173C4.60313 12.1062 5.38083 13.0237 6.38398 13.6302Z" />
        <path d="M18.2424 0C16.8138 0.00175606 15.4443 0.570023 14.4342 1.58016C13.424 2.5903 12.8558 3.95984 12.854 5.38839C12.856 5.65292 12.8782 5.91691 12.9204 6.17806C13.1955 6.14366 13.4725 6.12593 13.7499 6.12498C15.2993 6.12512 16.8091 6.61521 18.0632 7.52516C18.6703 7.08422 19.3418 6.73953 20.054 6.50323C20.599 4.9914 21.6246 3.69999 22.9738 2.82691C22.5127 1.97344 21.8297 1.26029 20.9969 0.762732C20.1641 0.265172 19.2125 0.00165714 18.2424 0V0Z" />
        <path d="M22.4429 6.13174C24.3844 6.15441 26.239 6.94074 27.6051 8.32053C28.9712 9.70033 29.7391 11.5626 29.7424 13.5043C29.7424 13.5441 29.7424 13.5905 29.7424 13.6304C30.4422 13.2149 31.0386 12.6461 31.4868 11.9668C31.935 11.2875 32.2233 10.5154 32.3301 9.7086C32.4368 8.90179 32.3591 8.08129 32.103 7.30883C31.8468 6.53637 31.4188 5.83206 30.8511 5.24891C30.2834 4.66575 29.5909 4.21894 28.8256 3.94208C28.0603 3.66522 27.2422 3.56553 26.4328 3.65052C25.6234 3.7355 24.8439 4.00294 24.1528 4.43271C23.4617 4.86248 22.877 5.44339 22.4429 6.13174Z" />
        <path d="M13.7498 8.11574C12.7739 8.11801 11.8168 8.38532 10.9809 8.88912C10.145 9.39292 9.46166 10.1143 9.00379 10.9762C8.54592 11.8381 8.33075 12.8082 8.38124 13.7829C8.43174 14.7576 8.746 15.7002 9.29049 16.5102C10.5699 15.5233 12.1407 14.9889 13.7565 14.9906C14.2354 14.9926 14.713 15.0392 15.1832 15.1299C14.9353 14.0576 14.933 12.9431 15.1767 11.8698C15.4203 10.7965 15.9034 9.79216 16.59 8.93196C15.7396 8.39656 14.7547 8.11351 13.7498 8.11574Z" />
        <path d="M13.7497 27.7581C14.7541 27.7564 15.7379 27.4737 16.5899 26.9419C15.5629 25.6673 14.9941 24.0846 14.9748 22.4478C14.9556 20.8111 15.487 19.2154 16.4837 17.917C16.3975 17.8042 16.3112 17.6914 16.2316 17.5719C15.4663 17.1694 14.6144 16.9598 13.7497 16.9614C12.3206 16.9614 10.9501 17.5291 9.93955 18.5397C8.92903 19.5502 8.36133 20.9207 8.36133 22.3498C8.36133 23.7789 8.92903 25.1495 9.93955 26.16C10.9501 27.1705 12.3206 27.7382 13.7497 27.7382V27.7581Z" />
        <path d="M26.8358 19.3638C25.5572 20.3528 23.9864 20.8894 22.3699 20.8894C20.7533 20.8894 19.1825 20.3528 17.9039 19.3638C17.2999 20.2497 16.9783 21.2977 16.9815 22.3699C16.9815 23.7989 17.5492 25.1695 18.5597 26.18C19.5702 27.1905 20.9408 27.7582 22.3699 27.7582C23.7989 27.7582 25.1695 27.1905 26.18 26.18C27.1905 25.1695 27.7582 23.7989 27.7582 22.3699C27.7614 21.2977 27.4398 20.2497 26.8358 19.3638Z" />
        <path d="M16.9814 13.5041C16.9814 14.5698 17.2975 15.6116 17.8896 16.4977C18.4816 17.3839 19.3232 18.0745 20.3078 18.4823C21.2924 18.8902 22.3758 18.9969 23.4211 18.789C24.4663 18.581 25.4264 18.0679 26.18 17.3143C26.9336 16.5607 27.4468 15.6006 27.6547 14.5553C27.8626 13.5101 27.7559 12.4267 27.3481 11.4421C26.9402 10.4575 26.2496 9.61591 25.3635 9.02383C24.4773 8.43175 23.4356 8.11572 22.3698 8.11572C20.9413 8.11748 19.5717 8.68575 18.5616 9.69588C17.5515 10.706 16.9832 12.0756 16.9814 13.5041Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36" height="35.8739" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

type LogoProps = {
  size?: string;
  color?: string;
};

export const Logo = (props: LogoProps) => {
  return <PotionIcon {...props} />;
};
