/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanResponse } from "../models/BooleanResponse";
import type { CreateUserRequest } from "../models/CreateUserRequest";
import type { Response_list_definitions_public_v1_feature_Feature__ } from "../models/Response_list_definitions_public_v1_feature_Feature__";
import type { Response_User_ } from "../models/Response_User_";
import type { Response_ValidateInviteCodeResponse_ } from "../models/Response_ValidateInviteCodeResponse_";
import type { UpdateUserRequest } from "../models/UpdateUserRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class UsersService {
  /**
   * Get User
   * @returns Response_User_ Successful Response
   * @throws ApiError
   */
  public static usersGetUser(): CancelablePromise<Response_User_> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/me",
      errors: {
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }
  /**
   * Get User Features
   * @returns Response_list_definitions_public_v1_feature_Feature__ Successful Response
   * @throws ApiError
   */
  public static usersGetUserFeatures(): CancelablePromise<Response_list_definitions_public_v1_feature_Feature__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/me/features",
    });
  }
  /**
   * Create User
   * @param requestBody
   * @returns Response_User_ Successful Response
   * @throws ApiError
   */
  public static usersCreateUser(
    requestBody: CreateUserRequest,
  ): CancelablePromise<Response_User_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/profile",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update User
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static usersUpdateUser(
    requestBody: UpdateUserRequest,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/profile",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Validate Invite Code
   * @param code Invite code
   * @returns Response_ValidateInviteCodeResponse_ Successful Response
   * @throws ApiError
   */
  public static usersValidateInviteCode(
    code: string,
  ): CancelablePromise<Response_ValidateInviteCodeResponse_> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/invite-code/valid",
      query: {
        code: code,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
