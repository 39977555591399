import { PropsWithChildren } from "react";

import { Cross1Icon } from "@radix-ui/react-icons";
import {
  DialogContent,
  DialogRoot,
  DialogTitle,
  Flex,
  IconButton,
  Responsive,
} from "@radix-ui/themes";
import { styled } from "@stitches/react";

const StyledDialogContent = styled(DialogContent, {
  position: "relative",

  variants: {
    search: {
      true: {
        position: "absolute",
        top: "calc(20vh + var(--space-4))",
        overflow: "visible",
      },
    },
    transparent: {
      true: {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
    },
  },
});

export type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  size?: Responsive<"1" | "2" | "3" | "4">;
  transparent?: boolean;
  search?: boolean;
  showCloseButton?: boolean;
}>;

const CloseButton = styled(IconButton, {
  cursor: "pointer",
  position: "absolute",
  top: "var(--space-4)",
  right: "var(--space-4)",
});

export const Modal = ({
  open,
  onClose,
  title,
  size,
  search,
  transparent,
  showCloseButton = true,
  children,
}: Props) => {
  return (
    <DialogRoot open={open}>
      <StyledDialogContent
        size={size}
        onPointerDownOutside={onClose}
        style={{ animation: "none" }}
        search={search}
        transparent={transparent}
        onEscapeKeyDown={onClose}
      >
        {!!title && (
          <DialogTitle>
            <Flex justify={"start"}>{title}</Flex>
          </DialogTitle>
        )}
        {children}
        {showCloseButton && (
          <CloseButton variant="ghost" onClick={onClose}>
            <Cross1Icon />
          </CloseButton>
        )}
      </StyledDialogContent>
    </DialogRoot>
  );
};
