import { CSSProperties, PropsWithChildren } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Table } from "@radix-ui/themes";

type Props = PropsWithChildren<{
  rowId: string;
  style?: CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}>;

export const Row = ({
  children,
  style: styleFromProps,
  onMouseEnter,
  onMouseLeave,
  rowId,
}: Props) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: rowId,
    animateLayoutChanges: () => false,
  });

  const style: CSSProperties = {
    ...styleFromProps,
    transform: CSS.Transform.toString(
      transform
        ? {
            ...transform,
            scaleY: 1,
          }
        : null
    ),
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <Table.Row
      style={style}
      ref={setNodeRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Table.Row>
  );
};
