/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum RawMaterialsForSubstancesFilter {
  ALL = "all",
  SINGLE_INGREDIENT = "single_ingredient",
  BLEND = "blend",
  SEARCH = "search",
}
