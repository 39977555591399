import { useEffect } from "react";

import { ArrowLeftIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { Link, useRouteError } from "react-router-dom";

import { Button } from "../Button";

const FullPage = styled(Flex, {
  width: "100%",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
});

const randomEmoji = () => {
  const emojis = ["👨‍🔬🧪💥", "👩‍🔬🧪💥", "🧑🏽‍🔬🧪💥", "👨🏽‍🔬🧪💥"];
  return emojis[Math.floor(Math.random() * emojis.length)];
};

export const ErrorBoundary = (): JSX.Element | null => {
  const routeError = useRouteError();

  useEffect(() => {
    if (((routeError as Error)?.message ?? "").includes("ChunkLoadError")) {
      // eslint-disable-next-line no-console
      console.error("ChunkLoadError: ", routeError);
    }
  }, [routeError]);

  return (
    <FullPage>
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap="6"
        height="100%"
        width="auto"
      >
        <Text mb="3" size="6">
          {"Something went wrong!"}
        </Text>
        <Text size="9" color="gray">
          {randomEmoji()}
        </Text>
        <Text size="2" color="gray">
          {
            "We're looking into it. In the meantime, you can try to reload the page or go back to the main page."
          }
        </Text>
        <Flex direction="row" gap="3">
          <Link to="/">
            <Button variant="soft">
              <ArrowLeftIcon />
              {"Go to main page"}
            </Button>
          </Link>
          <Button onClick={() => window.location.reload()}>
            <ReloadIcon />
            {"Reload"}
          </Button>
        </Flex>
      </Flex>
    </FullPage>
  );
};
