import { Badge } from "@radix-ui/themes";

type Props = {
  children: React.ReactNode;
} & typeof Badge.defaultProps;

export const Tag = ({ children, ...props }: Props) => {
  return (
    <Badge highContrast {...props}>
      {children}
    </Badge>
  );
};
