/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum SearchActionType {
  ADD_TO_PANTRY = "add_to_pantry",
  EXTERNAL_LINK_CLICK = "external_link_click",
}
