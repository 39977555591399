import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

import * as RadixToast from "@radix-ui/react-toast";

import { Toast, ToastProps } from "../common/components";

const ToastContext = createContext<{
  showToast: (toastProps: ToastProps) => void;
  closeToast: () => void;
}>({
  showToast: () => {},
  closeToast: () => {},
});

export const ToastContextProvider = ({ children }: PropsWithChildren) => {
  const [toastProps, setToastProps] = useState<ToastProps>({
    open: false,
  });

  const showToast = useCallback((toastProps: ToastProps) => {
    setToastProps({ ...toastProps, open: true });
  }, []);

  const closeToast = useCallback(() => {
    setToastProps({ open: false });
  }, []);
  return (
    <ToastContext.Provider
      value={{
        showToast,
        closeToast,
      }}
    >
      <RadixToast.Provider>
        <RadixToast.Viewport />
        {toastProps.open && <Toast {...toastProps} />}
        {children}
      </RadixToast.Provider>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(
      "useToastContext must be used within a ToastContextProvider"
    );
  }
  return context;
};
