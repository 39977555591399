import { useQuery, UseQueryOptions } from "react-query";

import { ApiError, User, UsersService } from "../api/public";

export const useGetMe = (options?: UseQueryOptions) => {
  return useQuery<User | undefined, ApiError, User>(
    "me",
    async () => {
      const { data, error } = await UsersService.usersGetUser();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: options?.onError,
      enabled: options?.enabled,
    }
  );
};
