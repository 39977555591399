import React from "react";

import { Flex } from "@radix-ui/themes";
import { styled, keyframes } from "@stitches/react";

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const Spinner = styled("div", {
  border: "var(--space-1) solid var(--gray-4)",
  borderTop: "var(--space-1) solid var(--accent-9)",
  borderRadius: "50%",
  width: "var(--space-1)",
  height: "var(--space-1)",
  animation: `${spin} 1s linear infinite`,

  variants: {
    size: {
      xs: { width: "var(--space-1)", height: "var(--space-1)" },
      sm: { width: "var(--space-2)", height: "var(--space-2)" },
      md: { width: "var(--space-4)", height: "var(--space-4)" },
      lg: { width: "var(--space-6)", height: "var(--space-6)" },
      xl: { width: "var(--space-9)", height: "var(--space-9)" },
    },
    centered: {
      true: { margin: "0 auto" },
    },
  },
});

type Sizes = "xs" | "sm" | "md" | "lg" | "xl";

export const FixedCenteredSpinner = ({ size = "sm" }: { size: Sizes }) => (
  <Flex
    justify={"center"}
    position="fixed"
    top="0"
    left="0"
    height="100%"
    width="100%"
    direction="column"
  >
    <Spinner size={size} centered />
  </Flex>
);

const Circle = styled("span", {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "50%",
  border: "2px solid white",
  backgroundColor: "var(--accent-11)",
});

const _calcStyles = (
  size: Sizes,
  index: number,
  total: number,
  scale = 1,
  inner = false
) => {
  let width = 6;
  let height = 6;

  switch (size) {
    case "xs":
      width = 12;
      height = 12;
      break;
    case "sm":
      width = 16;
      height = 16;
      break;
    case "md":
      width = 20;
      height = 20;
      break;
    case "lg":
      width = 24;
      height = 24;
      break;
    case "xl":
      width = 32;
      height = 32;
      break;
  }

  const angle = (360 / total) * index * (Math.PI / 180);
  let x = Math.cos(angle) * width * scale;
  let y = Math.sin(angle) * height * scale;

  if (inner) {
    x = x / 2;
    y = y / 2;
  }

  const slide = keyframes({
    from: {
      transform: "translate(-50%, -50%)",
    },
    to: {
      transform: `translate(calc(-50% + ${x}px), calc(-50% - ${y}px))`,
    },
  });

  return {
    width: `${width}px`,
    height: `${height}px`,
    animation: `${slide} 2s cubic-bezier(0, 1, .08, .755) infinite alternate`,
  };
};

const Container = styled("div", {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "50%",
  left: "50%",
});

export const PotionLogoSpinner = ({ size = "md" }: { size: Sizes }) => {
  const outerCircles = Array.from({ length: 8 }, (_, i) => (
    <Circle key={i} style={{ ..._calcStyles(size, i, 8, 1) }} />
  ));
  const innerCircles = Array.from({ length: 4 }, (_, i) => (
    <Circle
      key={`inner-${i}`}
      style={{ ..._calcStyles(size, i, 4, 1, true) }}
    />
  ));

  return (
    <Container>
      {outerCircles}
      <div style={{ rotate: "225deg" }}>{innerCircles}</div>
    </Container>
  );
};
