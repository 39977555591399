/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlendsResultRemoveRawMaterialRequest } from "../models/BlendsResultRemoveRawMaterialRequest";
import type { BlendsResultSelectRawMaterialRequest } from "../models/BlendsResultSelectRawMaterialRequest";
import type { BooleanResponse } from "../models/BooleanResponse";
import type { CreateBlendsResultRequest } from "../models/CreateBlendsResultRequest";
import type { Response_BlendsResult_ } from "../models/Response_BlendsResult_";
import type { Response_UUID_ } from "../models/Response_UUID_";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class BlendsService {
  /**
   * Create Blends Result
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static blendsCreateBlendsResult(
    requestBody: CreateBlendsResultRequest,
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/blends",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Blends Result
   * @param resultId
   * @returns Response_BlendsResult_ Successful Response
   * @throws ApiError
   */
  public static blendsGetBlendsResult(
    resultId: string,
  ): CancelablePromise<Response_BlendsResult_> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/blends/{result_id}",
      path: {
        result_id: resultId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Blends Result Select Raw Material
   * @param resultId
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static blendsBlendsResultSelectRawMaterial(
    resultId: string,
    requestBody: BlendsResultSelectRawMaterialRequest,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/blends/{result_id}/raw_materials",
      path: {
        result_id: resultId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Blends Result Remove Raw Material
   * @param resultId
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static blendsBlendsResultRemoveRawMaterial(
    resultId: string,
    requestBody: BlendsResultRemoveRawMaterialRequest,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/blends/{result_id}/raw_materials",
      path: {
        result_id: resultId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
