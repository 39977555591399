import { PropsWithChildren, useState } from "react";

import { Flex, Link } from "@radix-ui/themes";

type Props = PropsWithChildren<{
  showLabel?: string;
  hideLabel?: string;
  override?: boolean;
}>;

export const ShowMore = ({
  children,
  showLabel,
  hideLabel,
  override,
}: Props) => {
  const [showMore, setShowMore] = useState(false);

  return showMore || override ? (
    <Flex direction="column" gap="2">
      {children}
      <Link size="1" onClick={() => setShowMore(false)}>
        {hideLabel ?? "Show less"}
      </Link>
    </Flex>
  ) : (
    <Link size="1" onClick={() => setShowMore(true)}>
      {showLabel ?? "Show more"}
    </Link>
  );
};
