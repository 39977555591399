import { CSSProperties, useState } from "react";

import {
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Table as RadixTable } from "@radix-ui/themes";
import {
  TableOptions,
  useReactTable,
  getCoreRowModel,
  flexRender,
  RowData,
  Row as RowType,
  ColumnPinningState,
  Column,
} from "@tanstack/react-table";

import { Row } from "./Row";

const getCommonPinningStyles = <TData,>(
  column: Column<TData>
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px var(--gray-8) inset"
      : isFirstRightPinnedColumn
      ? "4px 0 4px -4px var(--gray-8) inset"
      : isPinned
      ? "0 0 0px var(--gray-8) inset"
      : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.98 : 1,
    position: isPinned ? "sticky" : "inherit",
    width: `${column.getSize()}px`,
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned ? "var(--color-panel)" : undefined,
    backdropFilter: isPinned ? "blur(12px)" : undefined,
    border: isPinned ? "none" : undefined,
  };
};

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    onMoveRow?: {
      (row: TData, from: number, to: number): void;
    };
    onTextInputCellUpdate?: (
      rowIndex: number,
      columnId: string,
      value: string
    ) => void;
    onSelectInputCellUpdate?: (
      rowIndex: number,
      columnId: string,
      value: string
    ) => void;
    onMultiSelectInputCellUpdate?: (
      rowIndex: number,
      columnId: string,
      value: string[]
    ) => void;
    getRowStyles?: (row: RowType<TData>) => React.CSSProperties;
    getHoveredRowIndex?: () => number | undefined;
    // this is a hack to access the input value in the table before its blurred
    intermediateInput?: {
      rowIndex: number;
      columnId: string;
      value: string;
    };
    setIntermediateInput?: (value?: {
      rowIndex: number;
      columnId: string;
      value: string;
    }) => void;
  }
}

type Props<TData> = Pick<
  TableOptions<TData>,
  | "columns"
  | "data"
  | "getRowId"
  | "enableColumnPinning"
  | "onColumnPinningChange"
> & {
  variant?: "surface" | "ghost";
  columnPinning?: ColumnPinningState;
  onCellUpdate?: (
    rowIndex: number,
    columnId: string,
    value: string | string[]
  ) => void;
  getRowStyles?: (row: RowType<TData>) => React.CSSProperties;
  onMoveRow?: (row: TData, from: number, to: number) => void;
};

export const Table = <TData extends RowData>({
  columns,
  data,
  columnPinning,
  variant = "surface",
  onCellUpdate,
  getRowStyles,
  getRowId,
  onMoveRow,
}: Props<TData>) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | undefined>();
  const [intermediateInput, setIntermediateInput] = useState<
    | {
        rowIndex: number;
        columnId: string;
        value: string;
      }
    | undefined
  >();
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    initialState: {
      columnPinning: columnPinning ?? {
        left: [],
        right: [],
      },
    },
    meta: {
      onMoveRow,
      onTextInputCellUpdate: onCellUpdate,
      onSelectInputCellUpdate: onCellUpdate,
      onMultiSelectInputCellUpdate: onCellUpdate,
      getRowStyles,
      getHoveredRowIndex: () => {
        return hoveredRowIndex;
      },
      intermediateInput,
      setIntermediateInput,
    },
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const rows = table.getRowModel().rows;
    const fromIndex = rows.findIndex((row) => row.id === event.active.id);
    const toIndex = rows.findIndex((row) => row.id === event.over?.id);

    if (fromIndex !== -1 && toIndex !== -1) {
      onMoveRow?.(rows[fromIndex].original, fromIndex, toIndex);
    }
  };
  const sensors = useSensors(useSensor(MouseSensor, {}));
  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <RadixTable.Root variant={variant} size="1">
        <RadixTable.Header>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <RadixTable.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <RadixTable.ColumnHeaderCell
                      key={header.column.id}
                      style={{
                        minWidth: header.getSize(),
                        width: header.getSize(),
                        maxWidth: header.getSize(),
                        ...getCommonPinningStyles(header.column),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </RadixTable.ColumnHeaderCell>
                  );
                })}
              </RadixTable.Row>
            );
          })}
        </RadixTable.Header>

        <RadixTable.Body>
          <SortableContext
            items={table.getRowModel().rows.map((row) => row.id)}
            strategy={verticalListSortingStrategy}
          >
            {table.getRowModel().rows.map((row) => {
              return (
                <Row
                  key={row.id}
                  style={table.options.meta?.getRowStyles?.(row)}
                  onMouseEnter={() => {
                    setHoveredRowIndex(row.index);
                  }}
                  onMouseLeave={() => {
                    setHoveredRowIndex(undefined);
                  }}
                  rowId={row.id}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <RadixTable.Cell
                        key={cell.id}
                        style={{
                          ...getCommonPinningStyles(cell.column),
                          width: cell.column.columnDef.size,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </RadixTable.Cell>
                    );
                  })}
                </Row>
              );
            })}
          </SortableContext>
          <DragOverlay dropAnimation={null} />
        </RadixTable.Body>
      </RadixTable.Root>
    </DndContext>
  );
};
