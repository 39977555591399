/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NormalizeINCIsRequest } from "../models/NormalizeINCIsRequest";
import type { Response_list_raw_materials_schema_substance_SubstanceOption__ } from "../models/Response_list_raw_materials_schema_substance_SubstanceOption__";
import type { Response_NormalizeINCIsResponse_ } from "../models/Response_NormalizeINCIsResponse_";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class IncisService {
  /**
   * Normalize Incis
   * @param requestBody
   * @returns Response_NormalizeINCIsResponse_ Successful Response
   * @throws ApiError
   */
  public static incisNormalizeIncis(
    requestBody: NormalizeINCIsRequest,
  ): CancelablePromise<Response_NormalizeINCIsResponse_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/incis/normalize",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Inci Options
   * @param qs
   * @param limit
   * @param offset
   * @returns Response_list_raw_materials_schema_substance_SubstanceOption__ Successful Response
   * @throws ApiError
   */
  public static incisGetInciOptions(
    qs?: string,
    limit: number = 100,
    offset?: number,
  ): CancelablePromise<Response_list_raw_materials_schema_substance_SubstanceOption__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/incis/options",
      query: {
        qs: qs,
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
