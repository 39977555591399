import { CSSProperties, ClassAttributes, useMemo } from "react";

import { Text } from "@radix-ui/themes";
import { DropzoneOptions, useDropzone } from "react-dropzone";

const baseStyle: CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 10,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

type Props = {
  onUpload: (files: File[]) => void;
  dragActiveText?: string;
  defaultText?: string;
  multiple?: boolean;
};

export const FileUpload = ({
  onUpload,
  dragActiveText = "Drop here!",
  defaultText = "Drag 'n' drop your files here!",
  multiple,
}: Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
    },
    onDrop: onUpload,
    multiple,
  } as unknown as DropzoneOptions);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div
      {...getRootProps({
        style,
      })}
    >
      <input {...(getInputProps() as ClassAttributes<HTMLInputElement>)} />
      {isDragActive ? (
        <Text size="2">{dragActiveText}</Text>
      ) : (
        <Text size="2">{defaultText}</Text>
      )}
    </div>
  );
};
