/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum UserRawMaterialSortableField {
  PUBLIC_RAW_MATERIAL_TRADE_NAME = "public_raw_material.trade_name",
  PUBLIC_RAW_MATERIAL_SUPPLIER_NAME = "public_raw_material.supplier_name",
  USER_PUBLIC_RAW_MATERIAL_CREATED_AT = "user_public_raw_material.created_at",
}
