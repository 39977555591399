/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBenchmarkProductRequest } from "../models/AddBenchmarkProductRequest";
import type { AddBenchmarkRawMaterialRequest } from "../models/AddBenchmarkRawMaterialRequest";
import type { BenchmarkEvalRequest } from "../models/BenchmarkEvalRequest";
import type { BenchmarkSearchRequest } from "../models/BenchmarkSearchRequest";
import type { Body_Product_Briefs_extract_product_brief } from "../models/Body_Product_Briefs_extract_product_brief";
import type { BooleanResponse } from "../models/BooleanResponse";
import type { DeformulateProductBriefRequest } from "../models/DeformulateProductBriefRequest";
import type { GenerateINCIListRequest } from "../models/GenerateINCIListRequest";
import type { Response_GenerateINCIListResult_ } from "../models/Response_GenerateINCIListResult_";
import type { Response_list_definitions_public_v1_product_brief_ProductBriefListing__ } from "../models/Response_list_definitions_public_v1_product_brief_ProductBriefListing__";
import type { Response_list_uuid_UUID__ } from "../models/Response_list_uuid_UUID__";
import type { Response_ProductBrief_ } from "../models/Response_ProductBrief_";
import type { Response_UUID_ } from "../models/Response_UUID_";
import type { UpdateBenchmarkProductRequest } from "../models/UpdateBenchmarkProductRequest";
import type { UpdateBenchmarkRawMaterialRequest } from "../models/UpdateBenchmarkRawMaterialRequest";
import type { UpdateProductBriefRequest } from "../models/UpdateProductBriefRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class ProductBriefsService {
  /**
   * Extract Product Brief
   * @param formData
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static productBriefsExtractProductBrief(
    formData?: Body_Product_Briefs_extract_product_brief
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/extract",
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Run Benchmark Searches
   * @param productBriefId
   * @param requestBody
   * @returns Response_list_uuid_UUID__ Successful Response
   * @throws ApiError
   */
  public static productBriefsRunBenchmarkSearches(
    productBriefId: string,
    requestBody: BenchmarkSearchRequest
  ): CancelablePromise<Response_list_uuid_UUID__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/run-benchmark-searches",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Evaluate Product Benchmarks
   * @param productBriefId
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static productBriefsEvaluateProductBenchmarks(
    productBriefId: string,
    requestBody: BenchmarkEvalRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/evaluate-product-benchmarks",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Evaluate Raw Material Benchmarks
   * @param productBriefId
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static productBriefsEvaluateRawMaterialBenchmarks(
    productBriefId: string,
    requestBody: BenchmarkEvalRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/evaluate-raw-material-benchmarks",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Generate Inci List
   * @param productBriefId
   * @param requestBody
   * @returns Response_GenerateINCIListResult_ Successful Response
   * @throws ApiError
   */
  public static productBriefsGenerateInciList(
    productBriefId: string,
    requestBody: GenerateINCIListRequest
  ): CancelablePromise<Response_GenerateINCIListResult_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/generate-inci-list",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Product Brief
   * @param productBriefId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static productBriefsUpdateProductBrief(
    productBriefId: string,
    requestBody: UpdateProductBriefRequest
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/product-briefs/{product_brief_id}/update",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Product Brief
   * @param productBriefId
   * @returns Response_ProductBrief_ Successful Response
   * @throws ApiError
   */
  public static productBriefsGetProductBrief(
    productBriefId: string
  ): CancelablePromise<Response_ProductBrief_> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/product-briefs/{product_brief_id}",
      path: {
        product_brief_id: productBriefId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * List Product Briefs
   * @returns Response_list_definitions_public_v1_product_brief_ProductBriefListing__ Successful Response
   * @throws ApiError
   */
  public static productBriefsListProductBriefs(): CancelablePromise<Response_list_definitions_public_v1_product_brief_ProductBriefListing__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/product-briefs",
    });
  }
  /**
   * Deformulate Product Brief
   * @param productBriefId
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static productBriefsDeformulateProductBrief(
    productBriefId: string,
    requestBody: DeformulateProductBriefRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/deformulate",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Add Benchmark Product
   * @param productBriefId
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static productBriefsAddBenchmarkProduct(
    productBriefId: string,
    requestBody: AddBenchmarkProductRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/benchmark-products",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Add Benchmark Raw Material
   * @param productBriefId
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static productBriefsAddBenchmarkRawMaterial(
    productBriefId: string,
    requestBody: AddBenchmarkRawMaterialRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/product-briefs/{product_brief_id}/benchmark-raw-materials",
      path: {
        product_brief_id: productBriefId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Remove Benchmark Product
   * @param productBriefId
   * @param benchmarkProductId
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productBriefsRemoveBenchmarkProduct(
    productBriefId: string,
    benchmarkProductId: string
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/product-briefs/{product_brief_id}/benchmark-products/{benchmark_product_id}",
      path: {
        product_brief_id: productBriefId,
        benchmark_product_id: benchmarkProductId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Benchmark Product
   * @param productBriefId
   * @param benchmarkProductId
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productBriefsUpdateBenchmarkProduct(
    productBriefId: string,
    benchmarkProductId: string,
    requestBody: UpdateBenchmarkProductRequest
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/product-briefs/{product_brief_id}/benchmark-products/{benchmark_product_id}",
      path: {
        product_brief_id: productBriefId,
        benchmark_product_id: benchmarkProductId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Remove Benchmark Raw Material
   * @param productBriefId
   * @param benchmarkRawMaterialId
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productBriefsRemoveBenchmarkRawMaterial(
    productBriefId: string,
    benchmarkRawMaterialId: string
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/product-briefs/{product_brief_id}/benchmark-raw-materials/{benchmark_raw_material_id}",
      path: {
        product_brief_id: productBriefId,
        benchmark_raw_material_id: benchmarkRawMaterialId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Benchmark Raw Material
   * @param productBriefId
   * @param benchmarkRawMaterialId
   * @param requestBody
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static productBriefsUpdateBenchmarkRawMaterial(
    productBriefId: string,
    benchmarkRawMaterialId: string,
    requestBody: UpdateBenchmarkRawMaterialRequest
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/product-briefs/{product_brief_id}/benchmark-raw-materials/{benchmark_raw_material_id}",
      path: {
        product_brief_id: productBriefId,
        benchmark_raw_material_id: benchmarkRawMaterialId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
