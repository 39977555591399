import React, { PropsWithChildren } from "react";

import { Tooltip as RadixTooltip } from "@radix-ui/themes";
import { styled } from "@stitches/react";

export const StyledTooltip = styled(RadixTooltip, {
  variants: {
    type: {
      error: {
        backgroundColor: "var(--red-10)",
        ".rt-TooltipArrow": {
          fill: "var(--red-10)",
        },
      },
    },
  },
});

type Props = PropsWithChildren<{
  content: NonNullable<React.ReactNode>;
  enabled?: boolean;
  type?: "error";
}>;

export const Tooltip = ({ content, children, enabled, type }: Props) => {
  if (enabled === false) {
    return <>{children}</>;
  }

  return (
    <StyledTooltip content={content} delayDuration={300} type={type}>
      {children}
    </StyledTooltip>
  );
};
export default Tooltip;
