/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum CompanyType {
  BRAND = "brand",
  CONTRACT_MANUFACTURER = "contract_manufacturer",
  INDEPENDENT = "independent",
  MANUFACTURER = "manufacturer",
  DISTRIBUTOR = "distributor",
  PRESS = "press",
  OTHER = "other",
}
