/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllRawMaterialsForSubstancesRequest } from "../models/AllRawMaterialsForSubstancesRequest";
import type { BooleanResponse } from "../models/BooleanResponse";
import type { CopyDeformulationResultRequest } from "../models/CopyDeformulationResultRequest";
import type { FunctionAssignmentRequest } from "../models/FunctionAssignmentRequest";
import type { RawMaterialsForSubstancesRequest } from "../models/RawMaterialsForSubstancesRequest";
import type { Response_DeformulationResult_ } from "../models/Response_DeformulationResult_";
import type { Response_list_definitions_public_v1_raw_materials_RawMaterial__ } from "../models/Response_list_definitions_public_v1_raw_materials_RawMaterial__";
import type { Response_list_raw_materials_services_deformulation_schema_DeformulationRawMaterialRecord__ } from "../models/Response_list_raw_materials_services_deformulation_schema_DeformulationRawMaterialRecord__";
import type { Response_SubstanceAliasesResponse_ } from "../models/Response_SubstanceAliasesResponse_";
import type { Response_UUID_ } from "../models/Response_UUID_";
import type { SubstanceAliasesRequest } from "../models/SubstanceAliasesRequest";
import type { UpdateDeformulationResultRequest } from "../models/UpdateDeformulationResultRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class DeformulationService {
  /**
   * Assign Functions
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static deformulationAssignFunctions(
    requestBody: FunctionAssignmentRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/deformulation/assign-functions",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * List Raw Materials For Substances
   * @param requestBody
   * @returns Response_list_raw_materials_services_deformulation_schema_DeformulationRawMaterialRecord__ Successful Response
   * @throws ApiError
   */
  public static deformulationListRawMaterialsForSubstances(
    requestBody: RawMaterialsForSubstancesRequest
  ): CancelablePromise<Response_list_raw_materials_services_deformulation_schema_DeformulationRawMaterialRecord__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/deformulation/raw-materials-for-substances",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * List All Raw Materials For Substances
   * @param requestBody
   * @returns Response_list_definitions_public_v1_raw_materials_RawMaterial__ Successful Response
   * @throws ApiError
   */
  public static deformulationListAllRawMaterialsForSubstances(
    requestBody: AllRawMaterialsForSubstancesRequest
  ): CancelablePromise<Response_list_definitions_public_v1_raw_materials_RawMaterial__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/deformulation/all-raw-materials-for-substances",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Deformulation Result
   * @param resultId
   * @returns Response_DeformulationResult_ Successful Response
   * @throws ApiError
   */
  public static deformulationGetDeformulationResult(
    resultId: string
  ): CancelablePromise<Response_DeformulationResult_> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/deformulation/results/{result_id}",
      path: {
        result_id: resultId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Deformulation Result
   * @param resultId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deformulationUpdateDeformulationResult(
    resultId: string,
    requestBody: UpdateDeformulationResultRequest
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/deformulation/results/{result_id}",
      path: {
        result_id: resultId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Copy Deformulation Result
   * @param requestBody
   * @returns Response_UUID_ Successful Response
   * @throws ApiError
   */
  public static deformulationCopyDeformulationResult(
    requestBody: CopyDeformulationResultRequest
  ): CancelablePromise<Response_UUID_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/deformulation/copy",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Substance Aliases
   * @param requestBody
   * @returns Response_SubstanceAliasesResponse_ Successful Response
   * @throws ApiError
   */
  public static deformulationGetSubstanceAliases(
    requestBody: SubstanceAliasesRequest
  ): CancelablePromise<Response_SubstanceAliasesResponse_> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/deformulation/substance/aliases",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Estimate Raw Material Recommended Usage
   * @param resultId
   * @param rowId
   * @param rawMaterialId
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static deformulationEstimateRawMaterialRecommendedUsage(
    resultId: string,
    rowId: string,
    rawMaterialId: string
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/deformulation/{result_id}/rows/{row_id}/recommended-usage",
      path: {
        result_id: resultId,
        row_id: rowId,
      },
      query: {
        raw_material_id: rawMaterialId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
