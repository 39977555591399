import { LightningBoltIcon } from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";

import { Tag } from "../Tag";

export const PoweredByAI = () => {
  return (
    <Box>
      <Tag color="red">
        <LightningBoltIcon /> Powered by AI
      </Tag>
    </Box>
  );
};

export default PoweredByAI;
