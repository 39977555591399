/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum SearchType {
  NOT_SPECIFIED = "not_specified",
  SEARCH_RAW_MATERIAL = "search_raw_material",
  SEARCH_PRODUCT = "search_product",
  DEFORMULATION_RESULT_RAW_MATERIAL = "deformulation_result_raw_material",
  BENCHMARK_RAW_MATERIAL = "benchmark_raw_material",
  BENCHMARK_PRODUCT = "benchmark_product",
}
