/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanResponse } from "../models/BooleanResponse";
import type { ListUserRawMaterialRequest } from "../models/ListUserRawMaterialRequest";
import type { Response_list_definitions_public_v1_raw_materials_RawMaterial__ } from "../models/Response_list_definitions_public_v1_raw_materials_RawMaterial__";
import type { Response_list_uuid_UUID__ } from "../models/Response_list_uuid_UUID__";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class PantryService {
  /**
   * List User Raw Materials
   * @param requestBody
   * @returns Response_list_definitions_public_v1_raw_materials_RawMaterial__ Successful Response
   * @throws ApiError
   */
  public static pantryListUserRawMaterials(
    requestBody: ListUserRawMaterialRequest,
  ): CancelablePromise<Response_list_definitions_public_v1_raw_materials_RawMaterial__> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/pantry",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * List User Raw Material Ids
   * @returns Response_list_uuid_UUID__ Successful Response
   * @throws ApiError
   */
  public static pantryListUserRawMaterialIds(): CancelablePromise<Response_list_uuid_UUID__> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/pantry/ids",
    });
  }
  /**
   * Add To User Pantry
   * @param rawMaterialId
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static pantryAddToUserPantry(
    rawMaterialId: string,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/pantry/{raw_material_id}",
      path: {
        raw_material_id: rawMaterialId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Remove From User Pantry
   * @param rawMaterialId
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static pantryRemoveFromUserPantry(
    rawMaterialId: string,
  ): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/pantry/{raw_material_id}",
      path: {
        raw_material_id: rawMaterialId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
