/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum SourceType {
  UNKNOWN = "unknown",
  ULP = "ulp",
  KNOWDE = "knowde",
  SPECIAL_CHEM = "special_chem",
  COVALO = "covalo",
  SUPPLIER = "supplier",
}
