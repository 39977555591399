import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";

export const AuthProvider = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();
  const isSetup = window.location.pathname.includes("/setup");
  const isSignup = window.location.pathname.includes("/signup");

  if (!(domain && clientId && redirectUri && audience)) {
    if (!domain) console.warn("REACT_APP_AUTH0_DOMAIN not set");
    if (!clientId) console.warn("REACT_APP_AUTH0_CLIENT_ID not set");
    if (!redirectUri) console.warn("REACT_APP_AUTH0_CALLBACK_URL not set");
    if (!audience) console.warn("REACT_APP_AUTH0_AUDIENCE not set");
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
        screen_hint: isSetup || isSignup ? "signup" : "login",
      }}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
    >
      <Outlet />
    </Auth0Provider>
  );
};
