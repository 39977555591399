import { useEffect, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { useGetMe } from "./useGetMe";
import { Routes } from "../routes";

type UseCheckProfileCompletenessOptions = {
  onError: (error: unknown) => void;
};

export const useCheckProfileCompleteness = ({
  onError,
}: UseCheckProfileCompletenessOptions) => {
  const { isAuthenticated } = useAuth0();
  const {
    data: me,
    isLoading,
    error,
  } = useGetMe({ onError, enabled: isAuthenticated });
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.status === 404) {
      navigate(Routes.setupProfile);
    }
  }, [error, navigate]);

  const isProfileComplete = useMemo(() => {
    if (!me) return undefined;
    return !!me.first_name && !!me.last_name;
  }, [me]);

  useEffect(() => {
    if (!me) return; // Don't navigate if user doesn't exist. This will be handled by the error handling above.
    if (isLoading) return; // Don't navigate if we're still loading

    if (isProfileComplete === false) {
      navigate(`${Routes.profile}?mode=finalize`);
    }
  }, [isProfileComplete, isLoading, navigate, me]);

  return {
    me,
    isLoading,
    error,
  };
};
