/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum SearchNamespace {
  RAW_MATERIAL = "raw_material",
  FORMULA = "formula",
}
