/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum JobRole {
  EARLY_ADOPTER = "early_adopter",
  PRODUCT_DEVELOPMENT_FORMULATION = "product_development_formulation",
  SALES_MARKETING = "sales_marketing",
  PURCHASING = "purchasing",
  PRODUCTION_MANUFACTURING = "production_manufacturing",
  CONSULTANT = "consultant",
  OTHER = "other",
}
