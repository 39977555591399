import { useEffect } from "react";

import { User } from "../../api/public";

export const useIntercom = (me?: User) => {
  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM_APP_ID && me && me.first_name) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: me.email,
        user_id: me.id,
        name: `${me.first_name} ${me.last_name}`,
      });
    }
  }, [me]);

  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).Intercom("update");
    }
  });
};
